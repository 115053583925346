import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    webType: "",
    webTarget: "",
    name: "",
    phone: "",
    email: ""
}

export const dataSlice = createSlice({
  name: 'data',
  initialState,
  reducers: {
    setWebType: (state, action) => {
      state.webType = action.payload
    },
    setTarget: (state, action) => {
        state.webTarget = action.payload
      },
    setName: (state, action) => {
    state.name = action.payload
    },
    setPhone: (state, action) => {
        state.phone = action.payload
    },
    setEmail: (state, action) => {
        state.email = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { setWebType, setTarget, setName, setPhone, setEmail } = dataSlice.actions

export default dataSlice.reducer