import React, { useState, useEffect } from 'react'
import FormWrapper from './FormWrapper'
import { motion } from 'framer-motion';
import { useSelector, useDispatch } from 'react-redux'
import { setTarget } from '../features/dataFormSlice'

const WebTarget = () => {

  const dataTarget = useSelector((state) => state.data.webTarget)
  const dispatch = useDispatch()
  const [choose, setChoose] = useState(null)



  const listItems = [
    "לספק מידע שימושי ועדכני בתחום התמחותי ולבסס את עצמי כמומחה בתחום",
    "לקרוא לפעולה: יצירת קשר או קביעת פגישה",
    "לשפר ולפתח את תדמית העסק",
    "לשמש כתיק עבודות (פורטפוליו) דיגיטלי",
    "לשווק את המוצרים או השירותים שלי ולהגיע ללקוחות פוטנציאליים חדשים",
    "אחר"
  ]
  useEffect(() => {
    if(dataTarget !== "")
      setChoose(listItems.indexOf(dataTarget));
  }, [])

  const clickHandler = (i) => {
    setChoose(i);
    dispatch(setTarget(listItems[i]))
  }

  return (
    <div className='w-full h-full flex flex-col items-center gap-5'>
      <h3 className='text-xl'>מהי המטרה העיקרית שלך מהאתר?</h3>
      <motion.ul className='w-5/6 flex flex-col items-center gap-3'>
        {listItems.map((item, index) => (
          <motion.li
            key={`target_item_${index}`}
            onClick={() => clickHandler(index)}
            className={`w-full md:w-1/2 border-2 rounded-full hover:bg-brand-blue hover:text-white ${choose === index ? "bg-brand-blue text-brand-white" : ""} p-1 pr-3 hover:cursor-pointer`}
            initial={{ opacity: 0, x: 300*index }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 1 }}
          >
            {item}
          </motion.li>
        ))}
      </motion.ul>
    </div>
  )
}

export default FormWrapper(WebTarget)