import React from 'react'
import { RiWhatsappFill } from "react-icons/ri";

const WhatsappButtun = () => {
  return (
    <a href="https://wa.me/+9720548238818?text=היי, הגעתי דרך הדף נחיתה" aria-label='קישור לכפתור ווטסאפ'>
        <RiWhatsappFill aria-label='כפתור ווטסאפ' size={50} color='#25D366' className='fixed bottom-5 left-5 '/>
    </a>
  )
}

export default WhatsappButtun