import React from 'react'
import FormWrapper from './FormWrapper'
import { useSelector, useDispatch } from 'react-redux'
import { setName, setEmail, setPhone } from '../features/dataFormSlice'

import { motion } from 'framer-motion';
const PersonalDetails = () => {

  const dispatch = useDispatch()


  const formInputs = [
    {
      name: "שם מלא",
      type: "text",
      setter: setName
    },
    {
      name: "מייל",
      type: "email",
      setter: setEmail
    },
    {
      name: "נייד",
      type: "tel",
      dir: "rtl",
      setter: setPhone
    },
  ]
  
  return (
    <div className='w-full h-full flex flex-col justify-center items-center gap-5'>
      <h3 className='text-xl'>פרטים אישיים</h3>

      {formInputs.map((item, index) => (
        <motion.input
          key={`form_input_${index}`}
          dir={item?.dir}
          type={item.type}
          onChange={(e) => dispatch(item.setter(e.target.value))}
          placeholder={item.name}
          className='form_input'
          initial={{ opacity: 0, x: 300*index }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 1 }}
        />
      ))}

      
    </div>
  )
}

export default FormWrapper(PersonalDetails)