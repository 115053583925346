import React from 'react'

const Copyright = () => {
  return (
    <div className='w-full h-20 bg-brand-white'>
        <div className='w-full h-full flex justify-center items-center'>
            <p dir='ltr'>&#169; {new Date().getFullYear()},<span className='text-brand-blue'><a href="https://tgaziel.co.il">Tgaziel.co.il</a></span> , All rights reserved.</p>
        </div>
    </div>
  )
}

export default Copyright