import React from 'react'
import FormWrapper from './FormWrapper'
import { useSelector, useDispatch } from 'react-redux'

const CompleteForm = () => {
  const fullData = useSelector((state) => state.data)
  console.log(fullData);
  return (
    <div className='w-full h-full flex flex-col items-center'>
      <div className='w-5/6 text-center'>
        <h3 className='text-2xl'>היי {fullData.name}</h3>
        <p className='text-lg'>כיף שפנית אלי! פרטיך התקבלו בהצלחה.</p>
        <p className='text-lg'>בקרוב מאוד אני אחזור אליך כדי להבין לעומק את הצורך ולתת את ההצעה הכי מותאמת שיכולה להיות בשבילך!</p>
      </div>

    </div>
  )
}

export default FormWrapper(CompleteForm)