import React, { useState } from 'react';
import { Stepper, Step } from 'react-form-stepper';
import { WebType,WebTarget,PersonalDetails,CompleteForm } from './formComponents'
import { IoIosSend } from "react-icons/io";
import { AnimatePresence, motion } from 'framer-motion';
import { useSelector } from 'react-redux'
import emailjs from '@emailjs/browser'; 


const FormStepper = () => {
    const [currentStepIndex, setCurrentStepIndex] = useState(0);
    const fullData = useSelector((state) => state.data)

    const formCompenents = [
        <WebType key={`web_type`}/>,
        <WebTarget key={`web_target`}/>,
        <PersonalDetails key={`personal_details`}/>,
        <CompleteForm key={`complete`}/>
    ]

    const nextStep = () => {
        switch (currentStepIndex) {
            case 0:
                if(fullData.webType === ""){
                    alert("יש לסמן את סוג האתר")
                    return null;
                }
                break;

            case 1:
                if(fullData.webTarget === ""){
                    alert("יש לסמן את מטרת האתר")
                    return null;
                }
                break;

            case 2:
                if(fullData.name === "" || fullData.email === "" || fullData.phone === ""){
                    alert("יש למלא את כל הפרטים")
                    return null;
                }
                break;
            default:
                break;
        }
        if(currentStepIndex < 3)
            setCurrentStepIndex(prevStep => prevStep + 1)
    }

    const prevStep = () => {
        if(currentStepIndex > 0)
            setCurrentStepIndex(prevStep => prevStep - 1)
    }


    const submitHandler = (e) => {
        e.preventDefault();
        if(fullData.name === "" || fullData.email === "" || fullData.phone === ""){
            alert("יש למלא את כל הפרטים")
            return null;
        }
        

        //SEND THE EMAIL
        const serviceId = 'dimenSight_email';
        const templateId = 'template_jdr81yu';
        const publicKey = 'iF_LIO_CbqSaJW1Gu';


        const templateParams = {
            to_name: "תומר",
            from_name: fullData.name,
            from_email: fullData.email,
            from_phone: fullData.phone,
            from_webType: fullData.webType,
            from_Target: fullData.webTarget

        }

        emailjs.send(serviceId, templateId, templateParams, publicKey)
            .then((res) => {
                if(res.status === 200)
                  setCurrentStepIndex(3)
            })

            .catch((err) => {
                console.error("Something Wrong: ", err)
            })
    }

    //-----------------------------------------------------------------//

    return (
            <form className='w-full h-5/6 md:h-3/6 lg:h-4/6 bg-brand-white flex flex-col justify-center gap-5 md:gap-0 pt-10' onSubmit={(e)=> submitHandler(e)}>
                <Stepper dir='ltr' activeStep={currentStepIndex} styleConfig={{activeBgColor: '#ECB94A', activeTextColor: '#000000', completedBgColor: '#121E2E'}} connectorStyleConfig={{ activeColor: '#121E2E', completedColor: '#121E2E' }} connectorStateColors={true} >
                    <Step key={"step_one"}  />
                    <Step key={"step_two"} />
                    <Step key={"step_three"} />
                    <Step key={"step_four"} />
                </Stepper>
                <AnimatePresence mode='wait'>
                    {formCompenents[currentStepIndex]}
                </AnimatePresence>

                <div className='w-full flex flex-row justify-center items-center gap-10'>
                    {currentStepIndex === 2 && (
                        <button type='submit' className='w-1/3 h-12 flex flex-row-reverse gap-2 items-center justify-center bg-brand-blue text-brand-yellow rounded-full drop-shadow-xl'>
                            <motion.div
                            >
                                <IoIosSend size={25}/>
                            </motion.div>
                            שלח
                        </button>
                    )}
                    {currentStepIndex < 2 && (
                        <button type='button' className='form_btn' onClick={nextStep}>הבא</button>
                    )}
                    {currentStepIndex > 0 && currentStepIndex < 3 && (
                        <button type='button' className='form_btn' onClick={prevStep}>קודם</button>
                    )}


                </div>
            </form>
    )
}




export default FormStepper