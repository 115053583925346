import React from 'react';
import { motion } from 'framer-motion';
import logo from './assets/LogoYellow.png';

const Hero = () => {

    
  return (
    <div className='w-full h-4/6 bg-brand-blue flex flex-col items-center md:justify-center gap-10'>
        <div className='w-full h-auto flex flex-col justify-center items-start md:items-center md:text-center pr-5 mt-10 md:mt-0'>
            <img src={logo} alt='tomer gaziel logo' className='w-24 self-center m-5'/>
            <motion.h1
                className='text-white text-3xl md:text-5xl'
                initial={{ 
                    opacity:0,
                    x:300
                 }}
                animate={{
                    opacity: 1,
                    x: 0
                }}
                transition={{
                    duration: 0.5
                }}
            >
                הגיע הזמן לבנות לעסק שלך
                <br />
                <span className='underline  decoration-brand-yellow text-4xl md:text-6xl'>
                אתר אינטרנט?
                </span>
            </motion.h1>
        </div>

        <div className='w-full h-auto flex flex-col justify-center items-start md:items-center md:text-center pr-5'>
            <motion.h2
                className='text-white text-xl md:text-3xl'
                initial={{ 
                    opacity:0,
                    x:300
                 }}
                animate={{
                    opacity: 1,
                    x: 0
                }}
                transition={{
                    duration: 0.5,
                    delay: 0.5
                }}
            >
                כנראה שאם אתה קורא את זה
                <br />
                התשובה היא&nbsp;
                <span className='text-3xl'>
                 כן
                    <span className='text-brand-yellow'>!</span>
                </span>
            </motion.h2>
        </div>
    </div>
  )
}

export default Hero