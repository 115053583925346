import React from 'react'
import { motion } from 'framer-motion';

const FormWrapper = (Component) => 
    function HOC() {
        return (
            <motion.section
            className='w-full h-full'
            initial={{ x: 300, opacity: 0 }}
            animate={{ x: 0, opacity: 1}}
            exit={{ x: -300, opacity: 0 }}
            transition={{ duration: 0.5 }}
            >
                <Component />
            </motion.section>
        )
    }


export default FormWrapper