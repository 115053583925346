import React, { useState } from 'react'
import { motion } from 'framer-motion';
import { AnimatePresence } from 'framer-motion';
import { FaHandPointLeft } from "react-icons/fa";
import { IoCloseCircle } from "react-icons/io5";

const ReasonModal = ({item, icon, animView}) => {

    const [showModal, setShowModal] = useState(false)


    const closeModal = () => {
        setShowModal(false)
    }
  return (
    <motion.div
        className='w-32 h-32 bg-brand-white rounded-xl shadow-md shadow-brand-blue hover:cursor-pointer hover:bg-brand-blue hover:text-brand-white'
        initial={{ 
            opacity:0,
         }}
        whileInView={{
            opacity: 1,
        }}
        transition={{
            duration: animView * 0.5
        }}
    >
        <div className='w-full h-full text-center flex flex-col justify-center items-center' onClick={() => setShowModal(true)}>
            {icon}
            {item.title}
        </div>
        
        <AnimatePresence>
            {showModal && (
                <motion.div
                    className='w-full h-full top-0 left-0 z-20 fixed bg-brand-blue flex flex-col items-center gap-6'
                    initial={{ opacity: 0, y:300 }}
                    animate={{ opacity: 1, y:0 }}
                    exit={{ opacity: 0, y:-300 }}
                    transition={{ duration: 0.3 }}
                    onClick={closeModal}
                >
                    <div className='w-full flex p-2'><IoCloseCircle className='' color='white' size={50} onClick={closeModal} /></div>
                    <div className='w-3/4 flex flex-col lg:gap-3'>
                        <h2 className='text-brand-yellow text-3xl lg:text-6xl'>{item.title}</h2>
                        <h3 className='text-2xl lg:text-4xl text-white'>{item.subtitle}</h3>
                    </div>
                    <ul className='w-3/4 flex flex-col gap-3 '>
                        {item.bullets && item.bullets.map((bullet) => (
                            <li className='w-full flex flex-row items-center gap-5 text-white text-md lg:text-lg'>
                                <div><FaHandPointLeft size={25} color='#ECB94A' /></div>
                                    {bullet}
                            </li>
                        ))}
                    </ul>
                    <p className='w-3/4 text-md lg:text-xl text-white'>
                        {item.para}
                    </p>
                </motion.div>
            )}
        </AnimatePresence>
    </motion.div>
  )
}

export default ReasonModal