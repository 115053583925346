import ContactUs from './ContactUs';
import Copyright from './Copyright';
import FormStepper from './FormStepper';
import Hero from './Hero';
import SixReasons from './SixReasons';
import WhatsappButtun from './WhatsappButtun';
import { CompleteForm } from './formComponents';

function App() {
  return (
    <div className='w-full h-full '>
      <Hero />
      <FormStepper />
      <SixReasons />
      <ContactUs />
      <Copyright />

      <WhatsappButtun />
    </div>
  );
}

export default App;
