import React from 'react'
import reasonsData from './reasonsContent'
import ReasonModal from './ReasonModal'
import { IoSpeedometer } from "react-icons/io5";
import { MdOutlinePriceCheck } from "react-icons/md";
import { TbSeo } from "react-icons/tb";
import { GrServices, GrShieldSecurity } from "react-icons/gr";
import { MdOutlineSecurity } from "react-icons/md";
import { FaPaintBrush } from "react-icons/fa";


const SixReasons = () => {

    const reasonsIcon = [
        <IoSpeedometer size={40} />,
        <MdOutlinePriceCheck size={40} />,
        <MdOutlineSecurity size={40} />,
        <FaPaintBrush size={40} />,
        <TbSeo size={40} />,
        <GrServices size={40} />
    ]
    
  return (
    <div className='w-full bg-brand-white flex flex-col items-center justify-center gap-10 pt-40 pb-20'>
        <h2 className='text-center text-2xl'>למה פיתוח אתר בקוד <br /> הוא הבחירה הנכונה ביותר?</h2>

        <div className='w-full md:w-4/6 flex flex-wrap items-center justify-center gap-8'>
            {reasonsData.map((item, index) => (
                    <ReasonModal key={`reason_item_${index}`} item={item} icon={reasonsIcon[index]} animView={index} />
            ))}
        </div>

        <div className='mt-10'>
          <a href="https://tgaziel.co.il">
            <h3 className='border-b-8 border-brand-yellow text-2xl'>לחץ כאן למעבר לאתר הראשי</h3>
          </a>
        </div>
    </div>
  )
}

export default SixReasons