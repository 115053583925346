const reasonsData = [
    {
        title: "מהירות וביצועים",
        subtitle: "מהירות האתר מושפעת ממספר גורמים מרכזיים:",
        bullets: [
            "סוג האחסון והשרת שמאחסנים עליו את האתר.",
            "אופן קבלת והצגת התכנים ללקוח.",
            "כמות הקוד והמשאבים הנדרשים בכל טעינת עמוד."

        ],
        para:`
        נכון, אתרי CMS קלים יחסית לפיתוח הודות לתבניות ומערכות מובנות,
        אך התוצאה היא לעיתים קרובות, קוד כבד ועמוס מדי.
        כתוצאה מכך נוצר עומס על השרת והאטה במהירות האתר.
        לעומת זאת, אתר המתוכנת בקוד נקי וממוקד כולל רק את הקוד והמשאבים ההכרחיים באמת עבור אותו אתר.
        זהו הפתרון היעיל ביותר לאתר מהיר ואיכותי.
        אתר כזה, גם חוסך בעלויות אחסון ותשתית בהשוואה לאתר CMS.
        `
        
        
    },
    {
        title: "עלויות",
        subtitle: "פיתוח אתר בקוד הוא ההשקעה הנבונה ביותר מבחינה כלכלית לטווח הארוך.",
        bullets: [
            "אין תשלומי רישיון על תוספים ופונקציות מתקדמות.",
            "אתר המפותח בקוד חוסך במשאבי תשתית יקרים כגון שרתים, אחסון ורוחב פס.",
            "ניתן לשנות ולהוסיף תכונות ללא עלות חודשית לצד ג’, אלא בתשלום חד פעמי בלבד."

        ],
        para:`
        אמנם הפיתוח הראשוני דורש השקעה גדולה יותר, אך החיסכון בטווח הארוך הופכים זאת להחלטה אסטרטגית נכונה.
        והתמורה אותה תקבלו גדולה בפער.
        `
        
        
    },
    {
        title: "אבטחת מידע",
        subtitle: "אתרים המפותחים בקוד ייעודי נהנים מרמת אבטחה גבוהה יותר",
        bullets: [
            "באתרים המפותחים בקוד מתוכנתים רק המרכיבים ההכרחיים לאותו אתר ספציפי, מה שמוריד משמעותית את האופציה לתקיפה.",
            "מיקום צד השרת, צד הלקוח ומסד הנתונים על תשתיות נפרדות.",
            "קל יותר ליישם אמצעי אבטחה מתקדמים כגון הצפנה, אימות משתמשים ועוד."

        ],
        para:`
        חשוב לציין שבמערכות CMS מבוססות קוד סגור, האבטחה היא ברמה גבוהה
        ואפשר להיות רגועים מבחינה זו.
        ` 
    },
    {
        title: "עיצוב וחדשנות",
        subtitle: "חופש עיצובי ויכולות חדשניות",
        bullets: [
            "אפשרויות עיצוב כמעט בלתי מוגבלות ושליטה מלאה על ה-IU וחווית המשתמש.",
            "שילוב אלמנטים אינטראקטיביים מתקדמים, כמו תלת ממד, אנימציות, מציאות רבודה ועוד.",
            "אינטגרציה עם כל מערכת חיצונית שרק נרצה."

        ],
        para:`
        בעידן הדיגיטלי, הנראות והמקצועיות ברשת הן מפתח להצלחה.
        לקוחות בוחרים במותגים שמשדרים אמינות וביטחון.
        בין אם אתם מוכרים שירות או מוצר פיזי - עיצוב אתר מרשים ואיכותי יוצר רושם חיובי אצל הלקוחות הפוטנציאלים, וגם אם הם עדיין לא סגורים על הרכישה,המטרה שלי היא לייצר חוויה מספיק איכותית כדי “שתיחרט” בזכרון לטווח הארוך עד הרגע בוא הלקוח יהיה מעוניין בשירות או במוצר בו התעניין.
        אתר מקצועי ואמין הוא המפתח להמרת לקוחות פוטנציאלים ללקוחות בפועל.  
        ` 
    },
    {
        title: "קידום אורגני",
        subtitle: "פיתוח אתר בקוד מציע יתרונות משמעותיים ב SEO",
        bullets: [
            "מהירות טעינה מיטבית אשר תורמת משמעותית לדירוג טוב בתוצאות החיפוש.",
            "שימוש בכלים טכנולוגים מתקדמים שאפשריים רק בקודותורמים משמעותית לקידום מהיר במנועי החיפוש.",
            "תשתית טכנית מקיפה לצרכי קידום אורגני מבלי להסתמך על תוספים ופלאגינים בתשלום."

        ],
        para:`
        אתר המפותח בקוד מאפשר שליטה טוטלית בסביבת הקידום האורגני.
        ניתן להטמיע באופן ישיר כל תשתית טכנית הדרושה ל OES
        ללא תוספים בתשלום חודשי וללא מגבלות.
        ` 
    },
    {
        title: "אחריות ושירות",
        subtitle: "תמיכה טכנית לטווח ארוך",

        para:`
        לאחר סיום בניית האתר, רבים מבוני האתרים אינם ממשיכים לתת תמיכה ולקחת אחריות על עבודתם.
        זה מותיר את בעלי העסקים ללא גיבוי טכני במקרה של תקלות, בעיות אבטחה או צורך בתחזוקה ושדרוגים.
        מצב זה עלול לגרום נזקים ועלויות מיותרות.

        אני נותן ללקוחותיי מחויבות ואחריות מלאה על פיתוח האתר לטווח ארוך.
        כולל זמינות גבוהה לתמיכה טכנית ותחזוקה שוטפת.
        בנוסף, לקוחות המאחסנים את אתרם בשרתים שלי זוכים, בביצועים מהירים יותר לאתר.

        הכל כדי להבטיח אמינות וביטחון לאורך זמן.
        ` 
    },

]

export default reasonsData