import React, { useEffect, useState } from 'react'
import FormWrapper from './FormWrapper';
import { motion } from 'framer-motion';
import { useSelector, useDispatch } from 'react-redux'

import { setWebType } from '../features/dataFormSlice'

import { CgWebsite } from "react-icons/cg";
import { MdLocalGroceryStore } from "react-icons/md";
import { PiAirplaneLandingFill } from "react-icons/pi";
import { TbBrandThreejs } from "react-icons/tb";


const WebType = () => {
  const dataType = useSelector((state) => state.data.webType)
  const dispatch = useDispatch()

  const [choose, setChoose] = useState(null)

  useEffect(() => {
    if(dataType !== "")
      setChoose(categories.indexOf(dataType));
  }, [])
  
  const categories = [
    "אתר תדמית",
    "דף נחיתה",
    "חנות",
    "תלת ממד",
  ]

  const categoryIcons = [
    <CgWebsite size={35}/>,
    <PiAirplaneLandingFill size={35}/>,
    <MdLocalGroceryStore size={35}/>,
    <TbBrandThreejs size={35}/>
  ]

  
  const clickHandler = (i) => {
    setChoose(i);
    dispatch(setWebType(categories[i]))
  }

  return (
    <div className='w-full h-full flex flex-col justify-center items-center gap-5'>
      <h3 className='text-xl'>מהו סוג האתר המתאים לצרכים שלך?</h3>
      <div className='w-full md:w-3/4 flex flex-row justify-center items-center flex-wrap gap-5'>
          {categories.map((category, index) => (
            <motion.div
              onClick={() => clickHandler(index)}
              key={`form_categort_${index}`}
              className={`w-32 h-32 lg:w-44 lg:h-44 border-2 flex flex-col justify-center items-center rounded-lg ${choose === index ? "bg-brand-blue text-brand-white" : ""} hover:bg-brand-blue hover:text-brand-white shadow-md hover:cursor-pointer`}
              initial={{ opacity: 0, x: 300*index }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 1 }}
              >
              {categoryIcons[index]}
              <p>{category}</p>
            </motion.div>
          ))}
      </div>
    </div>
  )
}

export default FormWrapper(WebType)